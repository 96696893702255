import axios from "axios";
import setingApi from './SettingApi'
import Swal from 'sweetalert2'
import { message } from 'antd'
import moment from "moment/moment";
import { getSession } from "next-auth/react"


var ctdtime = moment().format("hh:mm:ss")
var ctddate = moment().format("YYYY-MM-DD")

const StorageApi = function (data) {

}

const getSecondBearer = async (checkBearer) => {
  let data = {
    bearer: checkBearer
  }
  return new Promise((resolve,reject) =>{
    axios(setingApi.config('POST','login_v2/refresh_token',JSON.stringify(data),'',checkBearer))
    .then(function(rsp) {
      resolve(rsp);
    }).catch(function (error) {
      reject(error)
    })
  })
}

StorageApi.Refreshtoken = async (checkBearer) => {
  let _exp = localStorage.getItem('exp');
  if (!_exp) {
  _exp = null
  } else {
    _exp = localStorage.getItem('exp')
  }
  const now = moment().format('YYYY-MM-DD HH:mm:ss');
  if (_exp == null || moment(now).isAfter(_exp)) {
    const second_bearer = await getSecondBearer(checkBearer);
    const exp = moment().add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    localStorage.setItem('exp', exp);
    localStorage.setItem('refresh_token', second_bearer.data.data.second_bearer);
    return second_bearer
  } else {
    return {
      data: {
        data: {
          second_bearer: localStorage.getItem('refresh_token')
        }
      }
    }
  }
}

/*
  ============
  = GET API  =
  ============
 */
StorageApi.getData = async (tujuan, alert = true) => {
  const bearer = await getSession()
  const refresh_token = await StorageApi.Refreshtoken(bearer.bearer)
  return new Promise((resolve, reject) => {
    axios(setingApi.config('GET', tujuan, '', '',bearer ? bearer.bearer : '',refresh_token.data.data.second_bearer ))
      .then(function (rsp) {
        resolve(rsp);
      }).catch(function (error) {
        if (error.message == "Network Error") {
          if (alert) {
            Swal.fire({
              title: 'Error!',
              text: 'Permasalahan koneksi ke server',
              icon: 'error'
            })
          }
        }
        reject(error);
      })
  })
}

/*
  ===================
  = GET DETAIL API  =
  ===================
*/
StorageApi.detailData = async (tujuan, id) => {
  const bearer = await getSession()
  const refresh_token = await StorageApi.Refreshtoken(bearer.bearer)
  return new Promise((resolve, reject) => {
    axios(setingApi.config('GET', tujuan, '', id, bearer ? bearer.bearer : '',refresh_token.data.data.second_bearer))
      .then(function (rsp) {
        resolve(rsp);
      }).catch(function (error) {
        reject(error);
      })
  })
}

/*
  =============
  = POST API  =
  =============
*/
StorageApi.addData = async (tujuan, data, alert = true) => {
  var bearer = await getSession()
  if (bearer) {
    var ctdby = bearer.kode_user_master
    var Apidata = '';
    if (data.values) {
      Apidata = data
    }else{
      data = { ...data, ctdby: ctdby, kode_app: setingApi.kode_app, ctddate: moment().format("YYYY-MM-DD"), ctdtime: moment().format("H:mm:ss") }
      Apidata = JSON.stringify(data)
    }
  } else {
    var Apidata = '';
    if (data.values) {
      Apidata = data
    }else{
      data = { ...data, kode_app: setingApi.kode_app, ctddate: moment().format("YYYY-MM-DD"), ctdtime: moment().format("H:mm:ss") }
      Apidata = JSON.stringify(data)
    }
  }
  let url;
  if (tujuan != "register" && tujuan != "forgot_password") {
  const refresh_token = await StorageApi.Refreshtoken(bearer.bearer)
    url = axios(setingApi.config('POST', tujuan, Apidata, '', bearer ? bearer.bearer : '',refresh_token.data.data.second_bearer))
  }else{
    url = axios(setingApi.config('POST', tujuan, Apidata, '', bearer ? bearer.bearer : '',''))
  }
  return new Promise((resolve, reject) => {
     url.then(function (rsp) {
        if (alert) {
          Swal.fire({
            title: 'Success!',
            text: rsp.data.msg,
            icon: 'success'
          })
        }
        resolve(rsp);
      }).catch(function (error) {
        var message = '';
        if (!error.message || error.response.data.msg != undefined) {
          message = error.response.data.msg
        } else {
          message = error.message
        }

        if (typeof message == "object") {
          if (alert) {
            Swal.fire({
              title: 'Perhatian!',
              text: "System tidak dapat memproses",
              icon: 'warning'
            })
          }
        } else {
          if (alert) {
            Swal.fire({
              title: 'Error!',
              text: message,
              icon: 'error'
            })
          }
        }
        // debugger
        reject(error);
      })
  });
}


StorageApi.auth = (tujuan, data, alert = true) => {
  var Apidata = '';
  data = { ...data, kode_app: setingApi.kode_app }
  Apidata = data
  return new Promise((resolve, reject) => {
    axios(setingApi.config_auth('POST', tujuan, Apidata, ''))
      .then(function (rsp) {
        if (alert) {
          Swal.fire({
            title: 'Success!',
            text: rsp.data.msg,
            icon: 'success'
          })
        }
        resolve(rsp);
      }).catch(function (error) {
        // console.log(error);
        var message = '';
        if (error.response.data != undefined) {
          message = error.response.data.msg
        } else {
          message = 'Silahkan login'
        }
        if (typeof message == "object") {   
          if (alert) {
            Swal.fire({
              title: 'Perhatian!',
              text: "System tidak dapat memproses",
              icon: 'warning'
            })
          }
        } else {
          if (alert) {
            Swal.fire({
              title: 'Error!',
              text: message,
              icon: 'error'
            })
          }
        }
        reject(error);
      })
  });
}

/*
  ============
  = PUT API  =
  ============
*/
StorageApi.editData = async (tujuan, data, id, alert = true) => {
  var bearer = await getSession()
  if (bearer) {
    var ctdby = bearer.kode_user_master
      var Apidata = '';
      if (data.values) {
        Apidata = data
      }else{
        data = { ...data, ctdby: ctdby, kode_app: setingApi.kode_app, ctddate: moment().format("YYYY-MM-DD"), ctdtime: moment().format("H:mm:ss") }
        Apidata = JSON.stringify(data)
      }
    } else {
      var Apidata = '';
      if (data.values) {
        Apidata = data
      }else{
        data = { ...data, kode_app: setingApi.kode_app, ctddate: moment().format("YYYY-MM-DD"), ctdtime: moment().format("H:mm:ss") }
        Apidata = JSON.stringify(data)
      }
    }
    let refresh_token;
    let url;
    if (tujuan != "verifikasi") {
      refresh_token = await StorageApi.Refreshtoken(bearer.bearer)
      url = axios(setingApi.config('PUT', tujuan, Apidata, id, bearer ? bearer.bearer : '',refresh_token.data.data.second_bearer))
    }else{
      url = axios(setingApi.config('PUT', tujuan, Apidata, id, '',''))
    }
    return new Promise((resolve, reject) => {
    url.then(function (rsp) {
        if (alert) {
          Swal.fire({
            title: 'Success!',
            text: rsp.data.msg,
            icon: 'success'
          })
        }
        resolve(rsp);
      }).catch(function (error) {
        var message = '';
        if (!error.response.data.status) {
          message = error.response.data.msg
        } else {
          message = error.message
        }

        if (typeof message == "object") {
          if (alert) {
            Swal.fire({
              title: 'Perhatian!',
              text: "System tidak dapat memproses",
              icon: 'warning'
            })
          }
        } else {
          if (alert) {
            Swal.fire({
              title: 'Error!',
              text: message,
              icon: 'error'
            })
          }
        }
        reject(error);
      })
  });
}

/*
  ===============
  = DELETE API  =
  ===============
*/
StorageApi.hapusData = async (tujuan, id, data = [], alert = true) => {
  const bearer = await getSession()
  const refresh_token = await StorageApi.Refreshtoken(bearer.bearer)
  return new Promise((resolve, reject) => {
    Swal.fire({
      title: 'Delete',
      text: 'Apakah kamu ingin menghapus data ini ?',
      showDenyButton: true,
      confirmButtonText: 'Ya',
      denyButtonText: `Tidak`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios(setingApi.config('DELETE', tujuan, data, id, bearer ? bearer.bearer : '',refresh_token.data.data.second_bearer))
          .then(function (rsp) {
            if (alert) {
              Swal.fire({
                title: 'Success!',
                text: rsp.data.msg,
                icon: 'success'
              }).then(x => {
                resolve(rsp);
              })
            }
          }).catch(function (error) {
            var message = '';
            if (!error.response.data.status) {
              message = error.response.data.msg
            } else {
              message = error.message
            }

            if (typeof message == "object") {
              if (alert) {
                Swal.fire({
                  title: 'Perhatian!',
                  text: "System tidak dapat memproses",
                  icon: 'warning'
                })
              }
            } else {
              if (alert) {
                Swal.fire({
                  title: 'Error!',
                  text: message,
                  icon: 'error'
                })
              }
            }
            reject(error);
          })
      } else if (result.isDenied) {
        if (alert) {
          Swal.fire('Tidak jadi menghapus', '', 'info')
        }
      }
    })
  })
}

StorageApi.hapusDataLogin = async (tujuan, id, data = [], alert = true) => {
  const bearer = await getSession()
  const refresh_token = await StorageApi.Refreshtoken(bearer.bearer)
  return new Promise((resolve, reject) => {
    axios(setingApi.config('DELETE', tujuan, data, id, bearer ? bearer.bearer : '',refresh_token.data.data.second_bearer))
      .then(function (rsp) {
        if (alert) {
          Swal.fire({
            title: 'Success!',
            text: rsp.data.msg,
            icon: 'success'
          }).then(x => {
            resolve(rsp);
          })
        }
      }).catch(function (error) {
        var message = '';
        if (!error.response.data.status) {
          message = error.response.data.msg
        } else {
          message = error.message
        }

        if (typeof message == "object") {
          if (alert) {
            Swal.fire({
              title: 'Perhatian!',
              text: "System tidak dapat memproses",
              icon: 'warning'
            })
          }
        } else {
          if (alert) {
            Swal.fire({
              title: 'Error!',
              text: message,
              icon: 'error'
            })
          }
        }
        reject(error);
      })

  })
}

/*
  ===============
  = Relasi API  =
  ===============
*/
StorageApi.getRelasi = async (tujuan) => {
  const bearer = await getSession()
  let refresh_token = "";
  let url = "";
  if (tujuan != "sm_hrm/jenis_user") {
    refresh_token = await StorageApi.Refreshtoken(bearer.bearer)
    url = axios(setingApi.config('GET', tujuan, '', '', bearer ? bearer.bearer : '', refresh_token.data.data.second_bearer))
  }else{
    url = axios(setingApi.config('GET', tujuan, '', '', '', ''))
  }
  return new Promise((resolve, reject) => {
      url.then(function (rsp) {
        resolve(rsp)
      }).catch(function (e) {
        reject(e)
      })
  })
}

/*
  ===================
  = Sub Relasi API  =
  ===================
*/
StorageApi.getSubRelasi = async (tujuan, id) => {
  const bearer = await getSession()
  const refresh_token = await StorageApi.Refreshtoken(bearer.bearer)
  return new Promise((resolve, reject) => {
    axios(setingApi.config('GET', tujuan, '', '', bearer ? bearer.bearer : '',refresh_token.data.data.second_bearer))
      .then(function (rsp) {
        resolve(rsp);
      }).catch(function (error) {
        Swal.fire({
          title: 'Error!',
          text: error.response.data.msg,
          icon: 'error'
        })
        reject(error)
      })
  })
}

StorageApi.Notifikasi = async (type, pesan, param) => {

  type == "loading" ? message.loading(pesan, param)
    : type == "success" ? message.success(pesan, param)
      : type == "info" ? message.info(pesan, param)
        : type == "warning" ? message.warning(pesan, param)
          : type == "error" ? message.error(pesan, param)
            : ""

}

StorageApi.Akumulasi = async (tujuan) => {
  var hasil = []
  Promise.resolve(StorageApi.getData(tujuan))
    .then(value => {
      const data = value.data.data;
      hasil = data
    }).catch(error => {
    })
  return hasil;
}

export default StorageApi